const mockData = [
    {
      id: 1,
      title: "Community Mosque Expansion",
      description: "Our community mosque has been a cornerstone of spiritual and social activities for years. With the growing number of attendees, we need to expand our facilities to better serve our community. The expansion will include additional prayer halls, classrooms for religious education, a community kitchen, and more parking spaces. Your support will help us create a more inclusive and accommodating space for everyone.",
      goal: 100000,
      raised: 65000,
      backers: 220,
      imageUrls: [
        "https://upload.wikimedia.org/wikipedia/commons/3/32/Sultan_Ahmed_Mosque.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/9/93/Sheikh_Lotfollah_Mosque.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/5/58/Great_Mosque_of_Kairouan.jpg",
      ],
      leaders: ["Imam Ahmed", "Sister Fatima"],
      supporters: [
        {
          name: "Ali Khan",
          bio: "A dedicated community member and regular donor.",
          profileUrl: "https://randomuser.me/api/portraits/men/1.jpg",
        },
        {
          name: "Sara Ahmed",
          bio: "Passionate about community service and mosque activities.",
          profileUrl: "https://randomuser.me/api/portraits/women/2.jpg",
        },
      ],
      rewards: [
        {
          id: 1,
          title: "Bronze Supporter",
          description: "Receive a thank you card from the mosque.",
          amount: 50,
        },
        {
          id: 2,
          title: "Silver Supporter",
          description: "Receive a thank you card and a mention in the mosque newsletter.",
          amount: 100,
        },
        {
          id: 3,
          title: "Gold Supporter",
          description: "Receive a thank you card, a mention in the newsletter, and a special plaque.",
          amount: 500,
        },
        {
          id: 4,
          title: "Platinum Supporter",
          description: "All of the above plus a special dinner with mosque leaders.",
          amount: 1000,
        },
      ],
      summary: {
        what: "Expanding our mosque to include more facilities and space for the community.",
        how: "The funds will be used for construction, furnishings, and community programs.",
      },
      lastUpdated: "2024-08-11",
      allOrNothing: true,
      type: "Expansion",
      likes: 350,
      views: 1200,
      verified: true,
      daysLeft: 45,
    },
    {
      id: 2,
      title: "New Mosque Construction in Riverside",
      description: "The Riverside community is in dire need of a new mosque to accommodate the increasing number of worshippers. Our goal is to build a mosque that not only serves as a place of worship but also as a community center where people can gather, learn, and grow together. The new mosque will include prayer halls, a library, educational classrooms, and a community hall for events. Your contributions will help us lay the foundation for a stronger, more connected community.",
      goal: 150000,
      raised: 85000,
      backers: 300,
      imageUrls: [
        "https://upload.wikimedia.org/wikipedia/commons/4/45/Al-Azhar_Mosque.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/7/77/Faisal_Mosque_2007.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/e/ed/Hassan_II_Mosque.jpg",
      ],
      leaders: ["Imam Youssef", "Brother Ali"],
      supporters: [
        {
          name: "Fatima Zahra",
          bio: "A devoted community member committed to the mosque's mission.",
          profileUrl: "https://randomuser.me/api/portraits/women/3.jpg",
        },
        {
          name: "Hassan Ibrahim",
          bio: "An active volunteer and supporter of mosque initiatives.",
          profileUrl: "https://randomuser.me/api/portraits/men/4.jpg",
        },
      ],
      rewards: [
        {
          id: 1,
          title: "Supporter",
          description: "Receive a thank you note from the mosque.",
          amount: 25,
        },
        {
          id: 2,
          title: "Contributor",
          description: "Receive a thank you note and a mosque keychain.",
          amount: 75,
        },
        {
          id: 3,
          title: "Benefactor",
          description: "All of the above plus a special mention on our website.",
          amount: 150,
        },
      ],
      summary: {
        what: "Constructing a new mosque for the Riverside community.",
        how: "Funds will be allocated for construction materials, labor, and community programs.",
      },
      lastUpdated: "2024-08-10",
      allOrNothing: true,
      type: "Construction",
      likes: 200,
      views: 800,
      verified: true,
      daysLeft: 60,
    },
    {
      id: 3,
      title: "Renovation of Historic Mosque",
      description: "Our historic mosque, a landmark in the community, requires urgent renovations to preserve its beauty and functionality. The renovations will include structural repairs, modernizing facilities, and enhancing accessibility. By supporting this project, you are helping to preserve a piece of our heritage while ensuring it remains a safe and welcoming place for future generations.",
      goal: 200000,
      raised: 120000,
      backers: 450,
      imageUrls: [
        "https://upload.wikimedia.org/wikipedia/commons/3/3a/Sultan_Hassan_Mosque_Inside.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mosque_of_Uqba.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/e/ed/Konya_Mosque.jpg",
      ],
      leaders: ["Imam Karim", "Sister Leila"],
      supporters: [
        {
          name: "Mohamed Ali",
          bio: "Committed to preserving the mosque's legacy.",
          profileUrl: "https://randomuser.me/api/portraits/men/5.jpg",
        },
        {
          name: "Aisha Khalid",
          bio: "A regular donor and advocate for community projects.",
          profileUrl: "https://randomuser.me/api/portraits/women/6.jpg",
        },
      ],
      rewards: [
        {
          id: 1,
          title: "Friend of the Mosque",
          description: "Receive a thank you note and a special booklet on the mosque's history.",
          amount: 50,
        },
        {
          id: 2,
          title: "Patron",
          description: "All of the above plus a guided tour of the renovated mosque.",
          amount: 200,
        },
        {
          id: 3,
          title: "Guardian",
          description: "All of the above plus a commemorative plaque in your name.",
          amount: 500,
        },
      ],
      summary: {
        what: "Renovating our historic mosque to preserve its legacy.",
        how: "The funds will be used for structural repairs and modernizing facilities.",
      },
      lastUpdated: "2024-08-09",
      allOrNothing: true,
      type: "Renovation",
      likes: 500,
      views: 1500,
      verified: true,
      daysLeft: 30,
    },
    {
      id: 4,
      title: "Green Mosque Initiative",
      description: "Our mosque is embarking on a green initiative to become more environmentally friendly. The project includes installing solar panels, setting up a rainwater harvesting system, and creating a community garden. By supporting this initiative, you are helping us reduce our carbon footprint and promote sustainability within the community.",
      goal: 80000,
      raised: 45000,
      backers: 150,
      imageUrls: [
        "https://upload.wikimedia.org/wikipedia/commons/a/aa/Mosque_of_Muhammad_Ali.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/7/77/Badshahi_Mosque.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/e/e5/Umayyad_Mosque.jpg",
      ],
      leaders: ["Imam Sadiq", "Sister Maryam"],
      supporters: [
        {
          name: "Yasmin Alavi",
          bio: "An environmental enthusiast and community supporter.",
          profileUrl: "https://randomuser.me/api/portraits/women/7.jpg",
        },
        {
          name: "Omar Said",
          bio: "Active in promoting sustainability and green projects.",
          profileUrl: "https://randomuser.me/api/portraits/men/8.jpg",
        },
      ],
      rewards: [
        {
          id: 1,
          title: "Green Supporter",
          description: "Receive a thank you note and a plant seed packet.",
          amount: 30,
        },
        {
          id: 2,
          title: "Eco Warrior",
          description: "All of the above plus a reusable water bottle with the mosque's logo.",
          amount: 75,
        },
        {
          id: 3,
          title: "Sustainability Champion",
          description: "All of the above plus a plaque in the community garden.",
          amount: 150,
        },
      ],
      summary: {
        what: "Making our mosque environmentally friendly.",
        how: "Funds will be used for solar panels, rainwater harvesting, and a community garden.",
      },
      lastUpdated: "2024-08-08",
      allOrNothing: true,
      type: "Green Initiative",
      likes: 300,
      views: 1000,
      verified: true,
      daysLeft: 50,
    },
    {
      id: 5,
      title: "Youth Center for Mosque",
      description: "We are raising funds to establish a youth center within our mosque. The center will provide a safe space for young people to engage in educational and recreational activities, fostering a sense of community and belonging. The youth center will include a library, a computer lab, and spaces for sports and arts. Your contribution will help nurture the future leaders of our community.",
      goal: 120000,
      raised: 70000,
      backers: 250,
      imageUrls: [
        "https://upload.wikimedia.org/wikipedia/commons/2/2b/Al-Masjid_An-Nabawi.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/5/5b/Masjid-e-Jahan_Numa.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/4/4f/Putra_Mosque.jpg",
      ],
      leaders: ["Imam Abdullah", "Brother Jamal"],
      supporters: [
        {
          name: "Nadia Hussein",
          bio: "Dedicated to youth development and community engagement.",
          profileUrl: "https://randomuser.me/api/portraits/women/9.jpg",
        },
        {
          name: "Karim Omar",
          bio: "Passionate about providing opportunities for the youth.",
          profileUrl: "https://randomuser.me/api/portraits/men/10.jpg",
        },
      ],
      rewards: [
        {
          id: 1,
          title: "Youth Supporter",
          description: "Receive a thank you note and a custom bookmark.",
          amount: 25,
        },
        {
          id: 2,
          title: "Youth Advocate",
          description: "All of the above plus a special mention on our website.",
          amount: 100,
        },
        {
          id: 3,
          title: "Youth Champion",
          description: "All of the above plus a plaque in the youth center.",
          amount: 250,
        },
      ],
      summary: {
        what: "Establishing a youth center within our mosque.",
        how: "Funds will be used for creating educational and recreational spaces for the youth.",
      },
      lastUpdated: "2024-08-07",
      allOrNothing: true,
      type: "Youth Center",
      likes: 400,
      views: 1100,
      verified: true,
      daysLeft: 40,
    }];
  

export default mockData;